@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-sora: "Sora", sans-serif;
  --font-code: "Source Code Pro", monospace;
  --font-grotesk: "Space Grotesk", sans-serif;
  color-scheme: dark;
}

@layer base {
  html {
font-family: 'Raleway', sans-serif;

  }
}

*, *::before, *::after {
  box-sizing: border-box;
}

[data-tooltip] {
  color: gainsboro;
  position: relative;
}

[data-tooltip]:hover::before {
  content: attr(data-tooltip);
  position: absolute;
  top: 0;
}

.z {
  z-index: 10;
}
.jj {
  color:blue
}
.portfolio-description {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* transform: translate(-50%, -50%); */
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 5px;
  opacity: 0; /* Hide by default */
  transition: opacity 0.3s;

  width: full;
  height: 70%;


}

.clone-description {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: translate(-50%, -50%); */
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 5px;
  opacity: 0; /* Hide by default */
  transition: opacity 0.3s;
  object-fit: cover;
  width: full;
  height: 50%;
  overflow: auto;

}

.shadow-md:hover .clone-description {
  opacity: 1;
}
/* Show the description on hover */
.shadow-md:hover .portfolio-description {
  opacity: 1;
}

.image-test {
  height:40px;
  width: 40px;
  border-radius: .5rem;
}

/* .m {
  font-family: Source San Pro;
} */


.midd {
  --_gap: 2rem;
  /* display: flex; */
  margin: 0;
  padding: 0;
  list-style: none;

}

.bott {
  overflow: hidden;
  padding: calc(var(--_gap) / 2);
  position: relative;

}

.bott::after {
  content: "";
  height: 3px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: white;
  width: 100%;
  translate: var(--_translate, 0);
  scale: var(--_width, 0) 1;
  transition: scale 300ms var(--_scale-delay, 0ms), translate 500ms var(--_translate-delay, 0ms);


}

.section-title {
  font-family: Futura;
}

.asd {
  font-size: 4rem;
  padding: 2rem;
  font-weight: bold;
  margin: 0;
  text-align: center;
  font-family: Playfair Display;
}



  .bott:hover{
    --_width: 1;
  }
@supports selector(:has(h1)) {
  .bott:hover + li {
    --_translate: -100%;
    --_scale-delay: 300ms;
    --_translate-delay: 200ms;
  }


  .bott:has(+ :hover) {
    --_translate: 100%;
    --_scale-delay: 300ms;
    --_translate-delay: 200ms;
  }
}


@media (max-width: 460px) {
  .image-test {
    height:30px;
    width: 30px;
    border-radius: .5rem;
  }

}
